import logo from './logo.svg';
import Header from './core/header.jsx'
import './App.css';
import { Outlet } from 'react-router-dom';
import Footer from './core/footer.jsx';

function App() {
  return (
    <>
    <Header></Header>
    <Outlet />
    <Footer />
    </>
  );
}

export default App;
