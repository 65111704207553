import React from 'react';
import './about.css'; // Import the CSS file for styling

const About = () => {
    return (
        <div className="about-us">
            <div className="about-us-header">
                <h1>About Us</h1>
            </div>
            <div className="about-us-content">
                <div className="about-section">
                    <h2>Welcome to GEPS Engineering</h2>
                    <p>At GEPS Engineering, we specialize in industrial, PV solar, and fire control systems. Our mission is to provide innovative solutions and high-quality services to our clients, contributing to sustainable and efficient technologies.</p>
                </div>
                <div className="services-section">
                    <h2>Our Services</h2>
                    <ul>
                        <li>Industrial Engineering</li>
                        <li>PV Solar Installation</li>
                        <li>Fire Control Systems</li>
                    </ul>
                </div>
                <div className="team-section">
                    <h2>Our Team</h2>
                    <p>Our team consists of highly skilled engineers, technicians, and professionals dedicated to delivering outstanding results. We value teamwork, integrity, and continuous improvement, ensuring that every project we undertake is completed with precision and efficiency.</p>
                </div>
                <div className="contact-section">
                    <h2>Contact Us</h2>
                    <p>If you have any inquiries or would like to learn more about our services, please feel free to contact us:</p>
                    <p>Email: info@gepseng.in</p>
                    <p>Phone: +91 9336885254</p>
                </div>
            </div>
        </div>
    );
}

export default About;
