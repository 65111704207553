import React, { useState } from 'react';
import './footer.css';

const Footer = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { name, email, phone, message } = formData;

        if (!name || !email || !message) {
            setStatus('error');
            return;
        }

        setLoading(true);

        try {
            const response = await fetch('https://gepseng.in/api/web_api/web-footer-enquiry.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();

            if (response.ok) {
                setStatus('success');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
            } else {
                setStatus('error');
                console.error('API Error:', data);
            }
        } catch (error) {
            setStatus('error');
            console.error('Fetch Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section about">
                    <h3>About Us</h3>
                    <p>GEPS Engineering is a core contractor specializing in construction, maintenance, and manpower supply in the fields of electrical, mechanical, instrumentation, and PV solar projects, serving clients across India.</p>
                </div>
                <div className="footer-section contact">
                    <h3>Contact Us</h3>
                    <p>Email: <a href="mailto:info@gepseng.in">info@gepseng.in</a></p>
                    <p>Email: <a href="mailto:contact@gepseng.in">contact@gepseng.in</a></p>
                    <p>Email: <a href="mailto:help@gepseng.in">help@gepseng.in</a></p>
                    <p>Email: <a href="mailto:support@gepseng.in">support@gepseng.in</a></p>
                    <p>Phone: <a href="tel:+919336885254">+91 9336885254</a></p>
                    <p>Phone: <a href="tel:+918795524175">+91 8795524175</a></p>
                </div>
                <div className="footer-section inquiry">
                    <h3>Send an Inquiry</h3>
                    <form onSubmit={handleSubmit} className="inquiry-form">
                        <input type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
                        <input type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
                        <input type="tel" name="phone" placeholder="Your Phone Number" value={formData.phone} onChange={handleChange} />
                        <textarea name="message" placeholder="Your Message" value={formData.message} onChange={handleChange} required></textarea>
                        <button type="submit" disabled={loading}>{loading ? "Sending..." : "Send Inquiry"}</button>
                    </form>
                    {status === 'success' && <p className="success-msg">Your inquiry has been sent successfully.</p>}
                    {status === 'error' && <p className="error-msg">Failed to send inquiry. Please try again later.</p>}
                </div>
            </div>
            <div className="footer-bottom">
                <p>© {new Date().getFullYear()} GEPS Engineering. All rights reserved. | Developed by Aabid Husain &#123; BaniDeveloper &#125;.</p>
                <a href="https://siteadmin.gepseng.in/">Control Panel</a>
            </div>
        </footer>
    );
}

export default Footer;
