import React from 'react';
import './style.css';
import shop_new_2 from '../assets/img/geps_sec.png';
import shop_new_3 from '../assets/img/geps_third.png';
import img_2 from '../assets/img/img_2.png';
import solar_img from '../assets/img/solar_img.jpg';
import industrial from '../assets/img/industrial.png';
import inver_bat from '../assets/img/inverter_bat.avif';
import batt_main from '../assets/img/bat_pl_ma.jpeg';
import water_pv from '../assets/img/Water_Sprinkler.png';

const Home = () => {
    return (
        <div>
            <div id="carouselExampleAutoplaying1" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active corousel-mb">
                        <img src={shop_new_2} className="d-block img-fluid" alt="..." />
                    </div>
                    <div className="carousel-item corousel-mb">
                        <img src={shop_new_3} className="d-block img-fluid" alt="..." />
                    </div>
                    <div className="carousel-item corousel-mb">
                        <img src={img_2} className="d-block img-fluid" alt="..." />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying1" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying1" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className='sector-container'>
                <div className='sector-head' style={{ display: "flex", backgroundColor: "transparent", justifyContent: "center" }}>
                    <div style={{ flex: "1" }}>
                        <h2>Sectors</h2>
                    </div>
                    <div style={{ flex: "1", display: "flex", justifyContent: "end" }}>
                        <div className='sector-head-explore'>
                            <span>Explore More</span>
                        </div>
                    </div>
                </div>
                <div className='sector-item'>
                    <div className='card' data-text="Roof Top PV Solar">
                        <img src={solar_img} alt="a" className='sector-image' />
                    </div>
                    <div className='card' data-text="Fire Control System">
                        <img src={img_2} alt="a" className='sector-image' />
                    </div>
                    <div className='card' data-text="Industrial work">
                        <img src={industrial} alt="a" className='sector-image' />
                    </div>
                    <div className='card' data-text="Green Energy">
                        <img src={inver_bat} alt="a" className='sector-image' />
                    </div>
                    <div className='card' data-text="Recharable Battery Maintenance">
                        <img src={batt_main} alt="a" className='sector-image' />
                    </div>
                    <div className='card' data-text="Water Sprinkler On PV Solar">
                        <img src={water_pv} alt="a" className='sector-image' />
                    </div>
                </div>
            </div>
            <div className='area4-container'>
                <div className="area4-section">
                    <h2>About Us</h2>
                    <div>
                        <p>"GEPS Engineering" is a leading company specializing in industrial, PV solar, and fire control system sectors. With a strong focus on innovation and expertise, GEPS Engineering provides comprehensive solutions tailored to meet the diverse needs of industrial clients.</p>
                    </div>
                    <div>
                        <button className="btn btn-secondary" type='submit'>View More</button>
                    </div>
                </div>
                <div className="area4-section">
                    <h2>Contact Us</h2>
                    <div>
                        <p>Email 1 : contact@gepseng.in</p>
                        <p>Email 2 : aakhirhusain@gepseng.in</p>
                        <p>Phone: +91 9336885254</p>
                    </div>
                    <div>
                        <button className="btn btn-secondary" type='submit'>View More</button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home;