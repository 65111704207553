import React, { useState } from 'react';
import './contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [status, setStatus] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Add form validation
        if (!formData.name || !formData.email || !formData.message) {
            setStatus('error');
            return;
        }

        try {
            const response = await fetch('https://gepseng.in/api/web_api/web-footer-enquiry.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();

            if (response.ok) {
                setStatus('success');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
            } else {
                setStatus('error');
                console.error('API Error:', data);
            }
        } catch (error) {
            setStatus('error');
            console.error('Fetch Error:', error);
        }
    };

    return (
        <div className="contact-page">
            <div className="contact-header">
                <h1>Contact Us</h1>
            </div>
            <div className="contact-form">
                <form onSubmit={handleSubmit}>
                    <input type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
                    <input type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
                    <input type="tel" name="phone" placeholder="Your Phone Number" value={formData.phone} onChange={handleChange} />
                    <textarea name="message" placeholder="Your Message" value={formData.message} onChange={handleChange} required></textarea>
                    <input type="submit" value="Send Message" />
                </form>
                {status === 'success' && <p className="success-msg">Your message has been sent successfully.</p>}
                {status === 'error' && <p className="error-msg">Failed to send message. Please try again later.</p>}
            </div>
        </div>
    );
}

export default Contact;
