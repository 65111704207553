import React, { useState } from 'react';
import './joinus.css';

const JoinUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        position: '',
        education: '',
        mobileNo: '',
        cv: null,
        created_meta: ''
    });
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            cv: e.target.files[0]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const currentDateTime = new Date().toISOString();
        formData.created_meta = currentDateTime;

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('position', formData.position);
        formDataToSend.append('education', formData.education);
        formDataToSend.append('mobileNo', formData.mobileNo);
        formDataToSend.append('cv', formData.cv);
        formDataToSend.append('created_meta', formData.created_meta);  // Append created_meta to form data

        setLoading(true);

        try {
            const response = await fetch('https://gepseng.in/api/web_api/joinApplicant.php', {
                method: 'POST',
                body: formDataToSend
            });
            const data = await response.json();

            if (response.ok) {
                setStatus('success');
                setFormData({
                    name: '',
                    email: '',
                    position: '',
                    education: '',
                    mobileNo: '',
                    cv: null,
                    created_meta: ''
                });
            } else {
                setStatus('error');
                console.error('API Error:', data);
            }
        } catch (error) {
            setStatus('error');
            console.error('Fetch Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="join-us-container">
            {status === 'success' ? (
                <div className="success-message">
                    <h2>Thank You!</h2>
                    <p>Your application has been submitted successfully.</p>
                </div>
            ) : (
                <div className='main-join'>
                    <div className="join-us-form">
                        <h2>Join Us</h2>
                        <p>We are always looking for talented individuals to join our team. If you are interested in joining us, please fill in the form below.</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <input type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
                        <input type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
                        <input type="text" name="position" placeholder="Position You're Applying For" value={formData.position} onChange={handleChange} required />
                        <input type="text" name="education" placeholder="Your Education" value={formData.education} onChange={handleChange} required />
                        <input type="tel" name="mobileNo" placeholder="Your Mobile Number" value={formData.mobileNo} onChange={handleChange} required />
                        <input type="file" name="cv" accept=".pdf,.doc,.docx" onChange={handleFileChange} required />
                        <button type="submit" disabled={loading}>{loading ? 'Sending...' : 'Submit Application'}</button>
                        {status === 'error' && <p className="error-msg">Failed to submit application. Please try again later.</p>}
                    </form>
                </div>
            )}
        </div>
    );
}

export default JoinUs;